import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

import purpleTriangleEdges from '../../images/backgrounds/purple-triangle-edges.jpg'

const Forms = withTheme(() => (
  <Global
    styles={css`
      .leshen-form-submission-message {
        text-align: center;
      }

      .purple-triangle-edge-background {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(${purpleTriangleEdges});
        background-position: bottom;

        @media (max-width: 540px) {
          background-image: none;
          background-color: #02168c;
        }
      }

      .sms-form-thank-you {
        text-align: center;
        display: block;
        padding-bottom: 40px;
      }

      .policy-check-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .form {
          max-width: 300px;
          margin: 0 auto;
        }

        input {
          border-radius: 4px;
          border: none;

          @media (max-width: 540px) {
            width: 100% !important;
          }
        }

        button {
          border-radius: 32px !important;
          width: 100%;
          background-color: #1990ff;
          color: #fff;
          border: none;
          padding: 15px 20px;
          font-size: 24px;
        }

        .group {
          display: block;
        }
      }

      .sms-form {
        .leshen-brandy {
          font-size: 14px;
        }
      }

      .policy-tracker {
        .desktop {
          @media (max-width: 768px) {
            display: none;
          }
        }

        .mobile {
          display: none;

          @media (max-width: 768px) {
            display: block;
            padding: 20px 0 !important;
          }
        }
      }

      .policy-card-container {
        border: 3px solid #c8ccd0;
        display: flex;
        justify-content: space-evenly;
        max-width: 600px;
        margin: 30px auto 0;
        padding: 30px 15px;
        border-radius: 16px;

        @media (max-width: 540px) {
          display: block;
          text-align: center;
        }

        strong {
          font-family: 'Averta Bold', sans-serif !important;
        }

        .policy-image {
          display: flex;
          align-items: center;
          justify-content: center;

          .gatsby-image-wrapper {
            width: 150px;
            max-width: 200px;
            max-height: 150px;
          }
        }
        .policy-info {
          p {
            margin: 0;
          }
        }
      }

      .policy-tracker {
        .sms-form {
          .leshen-variable-content {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    `}
  />
))

export default Forms
