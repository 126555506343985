import React from 'react'
import { withTheme } from '@emotion/react'

import Forms from './Forms.styles'

const GlobalStyles = withTheme(() => (
  <>
    <Forms />
  </>
))

export default GlobalStyles
